console.warn("Module Cropper started!");

import Cropper from "cropperjs";
import axios from "axios";

window.addEventListener("DOMContentLoaded", function () {
  const image = document.getElementById("cropper-image");
  const modal = $("#cropper-modal");
  let cropper = undefined;
  let fieldCropper = "";
  let fieldOriginal = "";
  let originalFile = undefined;
  let inputClick = undefined;

  $(".zoom-in", modal).on("click", function () {
    cropper.zoom(0.1);
  });
  $(".zoom-out", modal).on("click", function () {
    cropper.zoom(-0.1);
  });

  Object.values(document.getElementsByClassName("js-cropper")).forEach(
    (input) =>
      input.addEventListener("change", function (e) {
        const files = e.target.files;
        const done = (url) => {
          input.value = "";
          image.src = url;
          modal.modal("show");
        };

        if (files && files.length > 0) {
          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            const reader = new FileReader();
            reader.onload = function (e) {
              done(reader.result);
            };
            reader.readAsDataURL(files[0]);
          }
        }

        inputClick = input;
        fieldCropper = input.getAttribute("data-field");
        fieldOriginal = input.getAttribute("data-original");
      })
  );

  modal
    .on("shown.bs.modal", function () {
      const dataWidth = inputClick.getAttribute("data-width");
      const dataHeight = inputClick.getAttribute("data-height");

      cropper = new Cropper(image, {
        aspectRatio: dataWidth / dataHeight,
        viewMode: 3,
        cropBoxResizable: false,
        dragMode: "move",
        autoCropArea: 1,
      });
    })
    .on("hidden.bs.modal", function () {
      cropper?.destroy();
      cropper = undefined;
      originalFile = undefined;
    });

  document.getElementById("crop").addEventListener("click", function () {
    const dataWidth = inputClick.getAttribute("data-width");
    const dataHeight = inputClick.getAttribute("data-height");
    const dataId = inputClick.getAttribute("data-id") || "";

    modal.modal("hide");

    if (cropper) {
      const canvas = cropper.getCroppedCanvas({
        width: dataWidth,
        height: dataHeight,
      });

      if (!dataId) {
        // For new entities (when id is not defined)
      }

      canvas.toBlob(function (blob) {
        const data = new FormData();
        data.append("file", blob);
        if (fieldOriginal) {
          data.append("original", originalFile);
        }

        axios({
          method: "post",
          url: Application.build_url(
            "cropper/" +
              inputClick.getAttribute("data-entity") +
              "/" +
              dataId +
              "/" +
              fieldCropper +
              "/" +
              fieldOriginal +
              "/"
          ),
          data,
          headers: { "X-Requested-With": "XMLHttpRequest" },
        })
          .then(() => window.location.reload())
          .catch(() => window.location.reload()); 
      }, "image/jpeg", 1);
    }
  });
});

