Application.UI = (function($) {

    function showMessage(options) {

        options = options || {};

        options = $.extend({
            message: '...',
            type: 'information'
        }, options);

        $('.feedback').remove();

        $feedback = $('<div class="feedback"><p class="message '+ options.type +'">' + options.message + ' <a href="javascript:;" class="close_feedback">Fechar</a></p></div>');

        if (options.type == 'success') {
            $feedback.addClass('success');
        }

        $feedback.prependTo('body');

        if (options.type != 'error') {
            Application.UI.removeMessage();
        }
    }

    function removeMessage() {
        setTimeout(function() { $('.feedback').remove(); }, 5000);
    }

    function showActivityStatus() {
        $('.feedback').remove();
        $feedback = $('<div class="feedback"><p class="message">Carregando...</div>');
        $feedback.prependTo('body');
    }

    function removeActivityStatus() {
        $('.feedback').remove();
    }

    return {
        'removeActivityStatus': removeActivityStatus,
        'showActivityStatus': showActivityStatus,
        'showMessage': showMessage,
        'removeMessage': removeMessage
    };

})(jQuery);